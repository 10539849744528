@import url(./icon/iconfont.css);
@font-face {
  font-family: "PingFangRegular";
  src: url("./../public/font/PingFangJ.ttf") format("woff"); }

@font-face {
  font-family: "PingFangMedium";
  src: url("./../public/font/PingFangBold.ttf") format("woff"); }

html,
body {
  height: 100%;
  font-size: 14px;
  overflow: auto;
  color: #4b5257;
  background-color: #fafafa;
  font-family: PingFangRegular;
  user-select: none; }

html,
body,
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0; }

a {
  text-decoration: none;
  color: inherit; }

button {
  outline: none;
  border: none;
  cursor: pointer; }

ul {
  list-style: none;
  padding: 0; }

.flex {
  display: flex; }

.flex-center {
  display: flex; }

.flex-grow {
  flex-grow: 2; }

.inline-block {
  display: inline-block; }

.clear:after {
  display: block;
  content: "";
  clear: both; }

.alignLeft {
  text-align: left; }

.alignRight {
  text-align: right; }

.center {
  text-align: center; }

.cursor {
  cursor: pointer; }

#container {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: #fafafa; }

.main-content {
  padding-top: 40px;
  padding-bottom: 30px;
  height: 100%; }
  .main-content aside {
    float: left; }
  .main-content > div {
    float: left;
    position: relative;
    z-index: 100; }

.right-page {
  width: 89.1%;
  height: 100%; }

.noData {
  color: gray;
  line-height: 4em;
  text-align: center; }

.ant-modal-header {
  font-family: PingFangMedium; }

.admin-page {
  height: 100%; }
  .admin-page .content-container {
    height: 100%;
    padding-left: 200px; }
    .admin-page .content-container > div.content {
      width: 100%;
      height: 100%; }
      .admin-page .content-container > div.content > div {
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: auto; }
  .admin-page .page-container h3 {
    padding: 0 35px;
    line-height: 60px;
    color: #666666;
    border-bottom: 1px solid #d2d2d2; }
  .admin-page .query {
    padding: 10px 15px 0px 15px; }
    .admin-page .query input {
      width: 160px;
      margin-left: 12px; }
    .admin-page .query .ant-select {
      min-width: 120px;
      margin-left: 12px; }
    .admin-page .query button {
      margin-left: 10px; }
      .admin-page .query button.add {
        float: right; }
  .admin-page .table-list {
    padding: 15px; }
    .admin-page .table-list table {
      width: 100%; }
      .admin-page .table-list table tbody {
        border-bottom: 1px solid #e2e2e2; }
        .admin-page .table-list table tbody tr {
          border-top: 1px solid #e2e2e2; }
          .admin-page .table-list table tbody tr:hover {
            background-color: #f0f4f8; }
      .admin-page .table-list table tr:nth-child(even) {
        background-color: #f7f7f7; }
      .admin-page .table-list table th {
        white-space: nowrap; }
      .admin-page .table-list table th,
      .admin-page .table-list table td {
        text-align: center;
        line-height: 40px; }
      .admin-page .table-list table .title {
        padding-left: 35px;
        text-decoration: underline;
        text-align: left; }
      .admin-page .table-list table .operate button {
        margin-right: 12px; }
      .admin-page .table-list table .operate a {
        display: inline-block;
        min-width: 64px;
        white-space: nowrap;
        margin: 0 8px;
        padding: 0 4px;
        line-height: 32px;
        border-radius: 4px;
        color: #FFA600;
        border: 1px solid #d9d9d9;
        text-align: center; }
        .admin-page .table-list table .operate a:hover {
          border-color: #FFA600; }
        .admin-page .table-list table .operate a.danger {
          color: #ee0808; }
          .admin-page .table-list table .operate a.danger:hover {
            border-color: #ee0808; }
        .admin-page .table-list table .operate a.default {
          color: #666; }
          .admin-page .table-list table .operate a.default:hover {
            border-color: #666; }
    .admin-page .table-list .ant-pagination {
      float: right;
      margin: 25px 0; }

.keyContainer {
  padding: 35px; }
  .keyContainer .key {
    margin-bottom: 25px; }
    .keyContainer .key > div {
      display: inline-block;
      vertical-align: top; }
    .keyContainer .key .name {
      width: 100px;
      line-height: 32px;
      margin-right: 15px;
      text-align: right; }
    .keyContainer .key .value-con {
      min-width: 300px;
      max-width: 600px;
      line-height: 32px; }
    .keyContainer .key .ant-select {
      min-width: 200px; }
    .keyContainer .key .ant-radio-group {
      line-height: 32px; }
    .keyContainer .key .tips {
      display: block;
      min-width: 700px;
      margin-left: 115px;
      color: gray; }
  .keyContainer .pics > span {
    display: inline-block; }
  .keyContainer .w-e-text-container {
    min-height: 400px;
    height: 600px !important; }

.single-item-key {
  display: flex;
  margin-top: 15px;
  align-items: center; }
  .single-item-key > div:nth-child(1) {
    white-space: nowrap;
    margin-right: 15px; }
  .single-item-key > div:nth-child(2) {
    flex-basis: 300px; }

.switchLanguage {
  position: absolute;
  width: 100px;
  top: 0;
  right: 0;
  margin-right: 2.1%;
  line-height: 40px;
  color: #FFF;
  cursor: pointer;
  text-align: center; }

.fullscreen-modal {
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important; }
  .fullscreen-modal > div {
    height: 100%; }
  .fullscreen-modal .ant-modal-body {
    position: relative;
    height: calc(100% - 55px);
    overflow-y: auto; }

.fullscreen-modal-confirm-order .anticon-close,
.bigButton .anticon-close {
  display: none; }

.fullscreen-modal-confirm-order .ant-modal-body,
.bigButton .ant-modal-body {
  height: calc(100% - 140px); }

.fullscreen-modal-confirm-order .ant-modal-footer button,
.bigButton .ant-modal-footer button {
  height: 65px;
  padding: 0 30px;
  line-height: 65px; }

.fullscreen-modal-confirm-order .ant-modal-footer > div button:nth-child(1),
.bigButton .ant-modal-footer > div button:nth-child(1) {
  float: left; }

.fullscreen-modal-confirm-order .ant-modal-footer .ant-btn,
.bigButton .ant-modal-footer .ant-btn {
  color: #fff;
  min-width: 106px;
  border-color: transparent;
  background-color: #FFA600; }

.fullscreen-modal-confirm-order .ant-modal-footer .ant-btn-primary,
.bigButton .ant-modal-footer .ant-btn-primary {
  background-color: #6696F7; }

button.green {
  color: #FFF !important;
  background-color: #6F9B37 !important;
  border-color: #6F9B37 !important; }

button.blue {
  color: #FFF !important;
  background-color: #2878F2 !important;
  border-color: #2878F2 !important; }
  button.blue:hover {
    background-color: rgba(40, 120, 242, 0.85) !important;
    border-color: rgba(40, 120, 242, 0.85) !important; }

button.bigsize {
  height: 55px;
  font-size: 18px;
  line-height: 55px; }

.redBorder {
  border: 1px solid red !important; }

.custom-scroll-bar-hide > div:nth-child(3) > div {
  background-color: transparent !important; }

.menuClickActive:active {
  background-color: #FEEFCD !important; }

.hidden {
  visibility: hidden; }

button.bigSize {
  width: 150px;
  height: 55px;
  line-height: 55px;
  margin-top: 20px; }

.switch-item-con {
  display: flex;
  justify-content: flex-start; }
  .switch-item-con .switch-item {
    margin-right: 15px;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 0 12px;
    line-height: 32px;
    cursor: pointer; }
    .switch-item-con .switch-item.active {
      color: #FFF;
      background-color: #ffa600; }

@media screen and (max-width: 1400px) {
  html #container {
    min-height: auto; }
  html .right-page {
    width: 93%; }
  html .main-content {
    padding-top: 20px;
    padding-bottom: 10px; }
  html .admin-page .content-container {
    padding-left: 160px; }
  html .switchLanguage {
    line-height: 18px; }
  html aside .head-img img {
    width: 55px;
    height: 55px; }
    html aside .head-img img span {
      font-size: 14px; }
  html aside .menu {
    width: 60px;
    height: 60px; }
  html aside .menu img {
    width: 23px;
    height: 23px; }
  html .no-desk .content .turn-page .iconfont {
    width: 30px; }
  html .desk-no .condition {
    top: -26px;
    z-index: 50; }
  html .container .status-container {
    display: none; }
  html .choice-page .edit-area .content .query-condition-title input {
    min-width: 60px !important; }
  html .takeaway-page-container {
    height: auto;
    background-color: transparent;
    box-shadow: none;
    margin-top: 0; }
    html .takeaway-page-container .iconfont {
      margin-top: 20px; }
  html .goods-property .ant-modal-body {
    height: 400px; }
  html .goods-kind .container {
    overflow-y: auto; }
  html .pick-change li {
    margin-bottom: 5px; }
  html .pick-change button {
    margin-top: 0; }
  html .index-page {
    width: 92%; }
  html .shop-list .edit-shop-list {
    width: 93%; }
    html .shop-list .edit-shop-list > div {
      width: 60%;
      height: calc(100% - 40px);
      top: 20px;
      right: 0.5%; }
  html .desk-no .content .item,
  html .no-desk .content .item {
    width: 13.7857%;
    margin-top: 6px;
    margin-right: 0.5%; }
    html .desk-no .content .item .note,
    html .no-desk .content .item .note {
      font-size: 12px !important; }
  html .goods-kind .custom-scroll-bar {
    height: calc(100vh - 145px) !important; }
  .admin-page .table-list table .operate a {
    margin: 0 2px;
    font-size: 12px;
    min-width: 60px;
    line-height: 26px; } }

@media screen and (min-width: 1400px) {
  .desk-no .content .item.small,
  .no-desk .content .item.small {
    width: 12.25% !important; } }

@media screen and (min-width: 1500px) {
  .main-content {
    padding-top: 60px;
    padding-bottom: 35px; } }

@media screen and (max-width: 1200px) {
  html .desk-no .content .item,
  html .no-desk .content .item {
    width: 16.1666%;
    margin-top: 6px;
    margin-right: 0.5%; }
    html .desk-no .content .item .note,
    html .no-desk .content .item .note {
      font-size: 12px !important; }
      html .desk-no .content .item .note .icon,
      html .no-desk .content .item .note .icon {
        width: 18px !important;
        font-size: 14px; } }

.order-detail-modal ul li {
  width: 20%; }
